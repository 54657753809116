<template>
  <v-card flat>
    <v-card-title>
      <barcode-scanner v-model="scanCode" :search="scanCode" :scannerPayload="scannerPayload"
      :loading="respectiveViewLoading"></barcode-scanner>
      <!-- <v-text-field solo dense hide-details v-model="scanCode" id="scanCode" autocomplete="off"
        ref="scanFocus" @click="selectContent" @change="getRespectiveProductOrder"
        :placeholder="$t('message.whsDashboard.scanText')"></v-text-field> -->
    </v-card-title>
    <v-card-text>
      <!-- <v-btn color="primary" block @click="$router.push('customer_orders')" class="text-center">
        {{ $t('message.whsDashboard.customerOrder') }}
        <v-icon class="mdi-spin ml-2" v-if="loading">mdi-loading</v-icon>
        <template v-else>({{ customerOrders }})</template>
      </v-btn> -->
      <v-btn color="primary" block @click="$router.push('supplier_orders')" class="text-center mt-5">
        {{ $t('message.whsDashboard.receiveSupplierOrder') }}
        <v-icon class="mdi-spin ml-2" v-if="loading">mdi-loading</v-icon>
        <template v-else>({{ supplierOrders }})</template>
      </v-btn>
      <v-btn color="primary" block @click="$router.push('transfer_orders')" class="text-center mt-5">
        {{ $t('message.whsDashboard.transferOrders') }}
        <v-icon class="mdi-spin ml-2" v-if="loading">mdi-loading</v-icon>
        <template v-else>({{ transferOrders }})</template>
      </v-btn>
      <v-btn color="primary" block @click="$router.push('adjustment_orders')" class="text-center mt-5">
        {{ $t('message.whsDashboard.adjustmentOrders') }}
        <v-icon class="mdi-spin ml-2" v-if="loading">mdi-loading</v-icon>
        <template v-else>({{ adjustmentOrders }})</template>
      </v-btn>
      <v-btn color="primary" block @click="$router.push('cycle_count')" class="text-center mt-5">
        {{ $t('message.whsDashboard.cycleCount') }}
        <v-icon class="mdi-spin ml-2" v-if="loading">mdi-loading</v-icon>
        <template v-else>({{ cycleCount }})</template>
      </v-btn>
      <v-btn color="primary" block @click="$router.push('product_view')" class="text-center mt-5">{{ $t('message.whsDashboard.product') }}</v-btn>
    </v-card-text>
  </v-card>
</template>
<script>
import { hostAppApi } from '@/plugins/axios_settings'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      customerOrders: 0,
      supplierOrders: 0,
      adjustmentOrders: 0,
      transferOrders: 0,
      cycleCount: 0,
      scanCode: '',
      loading: true,
      /* Scanner field */
      scannerPayload: {
        placeholder: 'message.whsDashboard.scanText',
        change: this.getRespectiveProductOrder,
        attrRef: 'scannerFieldFocus',
        attrId: 'scannerField'
      },
      respectiveViewLoading: false
    }
  },
  components: {
    'barcode-scanner': () => import('@/components/BarQrCodeScanner')
  },
  computed: {
    ...mapGetters(['getHostRefApi', 'userId'])
  },
  mounted () {
    this.getTotalCountOfOrders()
    requestAnimationFrame(() => {
      if (this.$refs.scanFocus) {
        this.$refs.scanFocus.focus()
      }
    })
    this.$store.commit('scannedValue', '')
  },
  methods: {
    getTotalCountOfOrders () {
      this.loading = true
      hostAppApi.get(`${this.getHostRefApi}orders/get_order_counts?user_id=${this.userId}`)
        .then((response) => {
          if (response.data) {
            const { customerordertotal, supplierordertotal, adjustmentordertotal, transferordertotal, ccsheettotal } = response.data
            this.customerOrders = customerordertotal
            this.supplierOrders = supplierordertotal
            this.adjustmentOrders = adjustmentordertotal
            this.transferOrders = transferordertotal
            this.cycleCount = ccsheettotal
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    selectContent () {
      if (this.$refs.scanFocus) {
        setTimeout(() => {
          document.getElementById('scanCode').select()
        }, 50)
      }
    },
    getRespectiveProductOrder () {
      this.respectiveViewLoading = true
      if (this.scanCode.trim()) {
        hostAppApi.get(`${this.getHostRefApi}orders/get_respective_view/${this.scanCode}`)
          .then((response) => {
            if (response && response.data && response.data.url && response.data.url.trim().length) {
              this.$store.commit('scannedValue', this.scanCode)
              this.$router.push(response.data.url.trim())
            } else this.$root.$emit('snackbar', { snackbar: true, color: 'info', text: 'message.common.no_records' })
          })
          .finally(() => { this.respectiveViewLoading = false })
      }
    }
  }
}
</script>
